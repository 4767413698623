import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "sozlesme";


var sozlesmeService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    sozlesmeListesi(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "sozlesmeListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeKategoriListesi() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "sozlesmeKategoriListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeListesiProje() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "sozlesmeListesiProje"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    surecRapor() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "surecRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeDetay(sozlesmeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sozlesmeID,
            serviceName: moduleName,
            methodName: "sozlesmeDetay"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeEkle(sozlesmeKategoriID, sozlesmeBaslik, yuzdeDeger, baslangicSuresi, isSuresi, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            sozlesmeKategoriID: sozlesmeKategoriID,
            sozlesmeBaslik: sozlesmeBaslik,
            yuzdeDeger: yuzdeDeger,
            baslangicSuresi: baslangicSuresi,
            isSuresi: isSuresi,
            durum: durum,
            methodName: "sozlesmeEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    sozlesmeSiraEkle(sozlesmeSirasi) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            sozlesmeSirasi: sozlesmeSirasi,
            methodName: "sozlesmeSiraEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    sozlesmeSurecAtamaList() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            methodName: "sozlesmeSurecAtamaList",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    kesisenSozlesmeler(sozlesmeID, baslamaTarihi, tamamlanmaTarihi) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sozlesmeID,
            baslamaTarihi,
            tamamlanmaTarihi,
            serviceName: moduleName,
            methodName: "kesisenSozlesmeler"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    kesisenSozlesmeler2(sozlesmeler) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            sozlesmeler,
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "kesisenSozlesmelerDizi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeKategoriAtamaList(musteriID, projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID: musteriID,
            projeID: projeID,
            serviceName: moduleName,
            methodName: "sozlesmeKategoriAtamaList"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeFilter(sozlesmeBaslik, sozlesmeKategori, yuzdeDeger, baslamaSuresi, isSuresi, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sozlesmeBaslik,
            sozlesmeKategori,
            yuzdeDeger,
            baslamaSuresi,
            isSuresi,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "sozlesmeArama"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "sozlesmeSayisi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    /*
       musteriAktifPasifSayisi() {
           var userDataBGSurec = localStorage.getItem("userDataBGSurec");
           var param = {
               token: userDataBGSurec,
               serviceName: moduleName,
               methodName: "musteriAktifPasifSayisi"
           }
           var result = new Promise(function(resolve, reject) {
               WsProvider.ajaxPost(serviceName, param).then((response) => {
                   resolve(response)
               }).catch(error => {
                   reject(error)
               })
           })
           return result
       },*/
    sozlesmeGuncelle(sozlesmeID, updateList) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sozlesmeID,
            updateList,
            serviceName: moduleName,
            methodName: "sozlesmeDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
}

export default sozlesmeService